;
(function ($) {
  $.fn.profileEdit = function (options) {
    options = $.extend({}, $.fn.profileEdit.defaults, options || {})

    function country_changed() {
      options.city_id.val('')
      options.city_text.autocomplete('setOptions', { params: { country: options.country_select.val() } })

      if ($.inArray(options.country_select.val(), options.country_regions) !== -1) {
        $.getJSON(options.region_url, { country: options.country_select.val() }, function (data) {
          options.region_text.val('').hide().closest('.layout-flexbox__cell').hide()
          var options_html = ''

          if (data.res) {
            $.each(data.regions, function (i, region) {
              options_html += '<option value="' + region.id + '">' + region.name + '</option>'
            })
          }

          options.region_select.html(options_html).show().closest('.layout-flexbox__cell').show()
        })

        if ($.inArray(options.country_select.val(), options.country_cities) !== -1) {
          options.city_text.val('').autocomplete('enable')
        }
      } else {
        options.region_select.hide().closest('.layout-flexbox__cell').hide()
        options.region_text.show().closest('.layout-flexbox__cell').show()
        options.city_text.autocomplete('disable').trigger('change')
      }

      $('#js').trigger('country_changed.profile')
    }

    function region_changed() {
      if ($.inArray(options.country_select.val(), options.country_cities) !== -1) {
        $.get(options.in_region_url, {
          region: options.region_select.val(),
          term  : options.city_text.val()
        }, function (res) {
          if (res.res === false) {
            options.city_id.val('')
            options.city_text.val('').trigger('change').autocomplete('enable')
          } else {
            options.city_text.val(res.data.city_name).trigger('change').autocomplete('enable')
            options.city_id.val(res.data.city_id)
          }
        }, 'json')
      } else if ($.inArray(options.country_select.val(), options.country_regions) !== -1) {
        options.city_id.val('')
        options.city_text.autocomplete('disable')
      } else {
        options.city_id.val('')
        options.region_select.hide().closest('.layout-flexbox__cell').hide()
        options.region_text.val('').show().closest('.layout-flexbox__cell').show()
        options.city_text.val('').autocomplete('disable').trigger('change')
      }
      $('#js').trigger('region_changed.profile')
    }

    function autocomplete_onselect(suggestion) {
      if (typeof suggestion.data !== 'undefined') {
        options.disable_change_event = true
        options.region_select.val(suggestion.data.region_id)
        options.city_id.val(suggestion.data.id)
        region_changed()
        setTimeout(function () {
          options.disable_change_event = false
        }, 100)
      } else {
        options.city_id.val('')
      }
    }

    function create_autocomplete(els) {
      if (els.length > 0) {
        els.on('change', function () {
          if (!options.disable_change_event) {
            options.city_id.val('')
          }
        })
          .autocomplete({
            serviceUrl               : options.city_url,
            tabDisabled              : true,
            onSelect                 : autocomplete_onselect,
            deferRequestBy           : 0,
            triggerSelectOnValidInput: false,
            forceFixPosition: true,
            params                   : { country: options.country_select.val() },
            formatResult             : function (suggestion, currentValue) {
              return '<a>' + suggestion.data.name + ' <span>' + suggestion.data.region_name + '</span></a>'
            },
            paramName                : 'term',
            transformResult          : function (response, originalQuery) {
              /*
               0 - city_id
               1 - city_name
               2 - region_id
               3 - country_id
               */
              return {
                query      : originalQuery,
                suggestions: typeof response.cities === 'undefined' ? [] : $.map(response.cities, function (el) {
                  return {
                    value: el[1],
                    data : {
                      id          : el[0],
                      name        : el[1],
                      region_id   : el[2],
                      region_name : response.regions[el[2]],
                      country_id  : el[3],
                      country_name: response.countries[el[3]]
                    }
                  }
                })
              }
            },
            autoSelectFirst          : true,
            dataType                 : 'json'
          })

        if ($.inArray(options.country_select.val(), options.country_regions) === -1) {
          els.autocomplete('disable')
        }
      }

      return els
    }

    return this.each(function () {
      var $this = $(this)

      options.el = $this

      options.country_select = $this.find('select.country')
      options.region_select = $this.find('select.region')
      options.region_text = $this.find('input.region')
      options.city_text = $this.find('input.city')
      options.city_id = $this.find('input.city_id')

      create_autocomplete(options.city_text)
      options.country_select.bind('change', country_changed)
      options.region_select.bind('change', region_changed)

      if (options.city_id.val().length === 0 && options.city_text.val().length === 0) {
        options.country_select.trigger('change')
      } else if ($.inArray(options.country_select.val(), options.country_regions) !== -1) {
        options.region_text.val('').hide().closest('.layout-flexbox__cell').hide()
        options.region_select.show().closest('.layout-flexbox__cell').show()
      } else {
        options.region_text.show().closest('.layout-flexbox__cell').show()
        options.region_select.hide().closest('.layout-flexbox__cell').hide()
      }
    })
  }

  $.fn.profileEdit.defaults = {
    disable_change_event  : false,
    country_select        : '',
    region_select         : '',
    region_text           : '',
    countries_with_regions: [],
    country_cities        : [],
  }
})(window.jQuery)
